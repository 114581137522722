import React, { SyntheticEvent } from 'react';

import { selectors } from '@grafana/e2e-selectors';
import { Tooltip, Form, Field, VerticalGroup, Button, Alert } from '@grafana/ui';
import { t, Trans } from 'app/core/internationalization';

import { submitButton } from '../Login/LoginForm';
import { PasswordField } from '../PasswordField/PasswordField';
interface Props {
  onSubmit: (pw: string) => void;
  onSkip?: (event?: SyntheticEvent) => void;
  showDefaultPasswordWarning?: boolean;
}

interface PasswordDTO {
  newPassword: string;
  confirmNew: string;
}

export const ChangePassword = ({ onSubmit, onSkip, showDefaultPasswordWarning }: Props) => {
  const submit = (passwords: PasswordDTO) => {
    onSubmit(passwords.newPassword);
  };
  return (
    <Form onSubmit={submit}>
      {({ errors, register, getValues }) => (
        <>
          {showDefaultPasswordWarning && (
            <Alert
              severity="info"
              title={t(
                'change-password.alert-title',
                'Continuing to use the default password exposes you to security risks.'
              )}
            />
          )}
          <Field
            label={t('change-password.new-pwd', 'New password')}
            invalid={!!errors.newPassword}
            error={errors?.newPassword?.message}
          >
            <PasswordField
              id="new-password"
              autoFocus
              autoComplete="new-password"
              {...register('newPassword', {
                required: t('change-password.new-pwd-required', 'New Password is required'),
              })}
            />
          </Field>
          <Field
            label={t('change-password.confirm-pwd', 'Confirm new password')}
            invalid={!!errors.confirmNew}
            error={errors?.confirmNew?.message}
          >
            <PasswordField
              id="confirm-new-password"
              autoComplete="new-password"
              {...register('confirmNew', {
                required: t('change-password.confirm-pwd-required', 'Confirmed Password is required'),
                validate: (v: string) =>
                  v === getValues().newPassword || t('change-password.pwd-match', 'Passwords must match!'),
              })}
            />
          </Field>
          <VerticalGroup>
            <Button type="submit" className={submitButton}>
              {t('change-password.submit', 'Submit')}
            </Button>

            {onSkip && (
              <Tooltip
                content={t(
                  'change-password.skip-tooltip',
                  'If you skip you will be prompted to change password next time you log in.'
                )}
                placement="bottom"
              >
                <Button fill="text" onClick={onSkip} type="button" aria-label={selectors.pages.Login.skip}>
                  {t('change-password.skip', 'Skip')}
                </Button>
              </Tooltip>
            )}
          </VerticalGroup>
        </>
      )}
    </Form>
  );
};
