import { css } from '@emotion/css';
import React from 'react';

import { useTheme2, LinkButton, VerticalGroup } from '@grafana/ui';
import { getConfig } from 'app/core/config';
import { t, Trans } from 'app/core/internationalization';

export const UserSignup = () => {
  const href = getConfig().verifyEmailEnabled ? `${getConfig().appSubUrl}/verify` : `${getConfig().appSubUrl}/signup`;
  const theme = useTheme2();
  const paddingTop = css({ paddingTop: '10px' });
  const hr = css({ height: '1px', color: theme.colors.text.primary, width: '100%', margin: 0 });

  return (
    <VerticalGroup>
      <hr className={hr} />
      <div className={paddingTop}>
        <Trans i18nKey="login.create-org-description">
          For organization admin only. Otherwise, please contact your admin to be invited.
        </Trans>
      </div>
      <LinkButton
        className={css`
          width: 100%;
          justify-content: center;
        `}
        href={href}
        variant="destructive"
      >
        {t('login.create-org', 'Create an organisation')}
      </LinkButton>
    </VerticalGroup>
  );
};
